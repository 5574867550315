'use client';
import { NextMark, TurboMark } from 'geist/logos';
import { ArrowUpRight } from 'geist/icons';
import type { MarkProps } from 'geist/src/logos/types';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import { AISDKIcon } from '../icons/ai-sdk';
import styles from './home-page.module.css';

interface Product {
  name: string;
  description: string;
  Icon: React.ForwardRefExoticComponent<
    Omit<MarkProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
  href: string;
  beta?: boolean;
}

export function ProductsSection(): JSX.Element {
  return (
    <div className={styles.productsSection}>
      <div className={styles.subheadingWrapper}>
        <div>
          <p className={styles.subheadingByline}>Our ecosystem</p>
          <h2 className={styles.subheading}>
            Explore Vercel&apos;s open source frameworks
          </h2>
        </div>
      </div>
      <div className={styles.products}>
        {[
          {
            name: 'Next.js',
            description:
              'A React framework that gives you building blocks to create web applications.',
            Icon: NextMark,
            href: 'https://nextjs.org/',
          },
          {
            name: 'Turborepo',
            description:
              'A high-performance build system for JavaScript and TypeScript codebases.',
            Icon: TurboMark,
            href: 'https://turbo.build/repo',
          },
          {
            name: 'Vercel AI SDK',
            description:
              'An open source library for building AI-powered user interfaces.',
            Icon: AISDKIcon,
            href: 'https://sdk.vercel.ai/docs',
          },
        ].map((product: Product) => (
          <Link
            className={styles.product}
            href={product.href}
            key={product.name}
            onClick={(): void => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs-cta',
                click_value: `Clicked on ${product.name} product`,
              });
            }}
            target="blank"
          >
            <div>
              <product.Icon size={32} />
            </div>
            <div className={styles.productText}>
              <p className={styles.productTitle}>
                <span>{product.name}</span>
                <span className={styles.productTitleArrow}>
                  <ArrowUpRight color="var(--accents-4)" size={10} />
                </span>
                {Boolean(product.beta) && (
                  <span className={styles.productTitleAlpha}>Beta</span>
                )}
              </p>
              <p className={styles.productDescription}>{product.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
