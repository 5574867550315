'use client';
import { clsx } from 'clsx';
import { ButtonLink } from '@pyra/multi-zone/link';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import { Guides } from '#/app/components/guides';
import styles from './home-page.module.css';

interface GuidesSectionProps {
  title?: string;
}

export function GuidesSection({
  title = 'The Vercel Guides',
}: GuidesSectionProps): JSX.Element {
  return (
    <div className={styles.guidesSection}>
      <div className={styles.subheadingWrapper}>
        <div>
          <p className={styles.subheadingByline}>Find a solution</p>
          <h2 className={styles.subheading}>{title}</h2>
        </div>
        <div className={clsx(styles.guidesButtonWrapper, styles.desktopOnly)}>
          <ButtonLink
            href="/guides"
            onClick={(): void => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs-cta',
                click_value: 'view_more_guides',
              });
            }}
            type="secondary"
            width="100%"
          >
            View More Guides
          </ButtonLink>
        </div>
      </div>

      <Guides guides={guides} />

      <div className={clsx(styles.guidesButtonWrapper, styles.mobileOnly)}>
        <ButtonLink
          href="/guides"
          onClick={(): void => {
            analytics.track(AnalyticsEvent.CLICK_EVENT, {
              click_name: 'docs-cta',
              click_value: 'view_more_guides',
            });
          }}
          type="secondary"
          width="100%"
        >
          View More Guides
        </ButtonLink>
      </div>
    </div>
  );
}

const guides = [
  {
    title: 'Fine-tuning GPT with OpenAI, Next.js and Vercel AI SDK',
    subtitle: 'AI & Next.js',
    icon: 'squircle' as const,
    href: '/guides/fine-tuning-openai-nextjs',
  },
  {
    title: 'OpenAI Functions with Next.js and the Vercel AI SDK',
    subtitle: 'AI & Next.js',
    icon: 'triple-venn' as const,
    href: '/guides/openai-function-calling',
  },
  {
    title: 'What is a Large Language Model (LLM)?',
    subtitle: 'AI & Language Models',
    icon: 'triangles' as const,
    href: '/guides/what-is-a-large-language-model',
  },
  {
    title: 'Building E-commerce Sites with Next.js and Shopify',
    subtitle: 'Databases & CMS',
    icon: 'circles' as const,
    href: '/guides/building-ecommerce-sites-with-next-js-and-shopify',
  },
];
