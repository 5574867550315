/* TODO: This file uses styled jsx. If it's converted to CSS modules it may not need this directive. */
'use client';

import PropTypes from 'prop-types';
import { Avatar, GenericAvatar } from './avatar';
import { DatoCMSAvatar } from './datocms-avatar';
import styles from './group.module.css';

function AvatarGroup({ members, limit = 3, size = 22 }) {
  const firstThree = members.slice(0, limit);
  const rest = members.slice(limit);
  return (
    <main className={styles.main}>
      {firstThree.map((member) =>
        // eslint-disable-next-line no-nested-ternary
        member.datocmsResponsiveImageData ? (
          <span className={styles.avatar} key={member.slug}>
            <DatoCMSAvatar
              data={member.datocmsResponsiveImageData}
              name={member.name}
              size={size}
              slug={member.slug}
              title={member.title}
            />
          </span>
        ) : member.githubUser ? (
          <span
            className={styles.avatar}
            key={member.username}
            title={member.name}
          >
            <GenericAvatar
              size={size}
              src={`https://github.com/${member.username}.png`}
              title={member.name || member.username}
            />
          </span>
        ) : (
          <span
            className={styles.avatar}
            key={member.uid || member.username}
            title={member.name}
          >
            {member.username ? (
              <Avatar
                size={size}
                title={member.name || member.username}
                username={member.username}
              />
            ) : (
              <Avatar
                size={size}
                title={member.name || member.username}
                uid={member.uid}
              />
            )}
          </span>
        ),
      )}
      {rest.length ? <span className={styles.note}>+{rest.length}</span> : null}
      <style jsx>{`
        main {
          display: flex;
          width: 100%;
          align-items: center;
        }
        .avatar {
          display: inline-flex;
          align-items: center;
        }
        .avatar:nth-child(n + 2) {
          margin-left: -10px;
        }
        .note {
          font-size: 13px;
          line-height: 16px;
          display: inline-flex;
          padding-left: 5px;
          justify-content: flex-end;
          margin-right: auto;
        }
      `}</style>
    </main>
  );
}

AvatarGroup.propTypes = {
  members: PropTypes.array,
  limit: PropTypes.number,
  size: PropTypes.number,
};

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default AvatarGroup;
