'use client';
import { useEffect, useState, type JSX } from 'react';
import { clsx } from 'clsx';
import { Button, Text } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import { getCookie, setCookie } from '@pyra/cookie';
import styles from '#/app/components/layout/docs.module.css';

interface Article {
  path: string;
  sectionTitle: string;
  pageTitle: string;
}

const popularArticleData = [
  {
    path: '/docs/pricing',
    sectionTitle: 'Pricing',
    pageTitle: 'Vercel Pricing',
  },
  {
    path: '/docs/frameworks',
    sectionTitle: 'Frameworks',
    pageTitle: 'Supported Frameworks on Vercel',
  },
  {
    path: '/docs/security/secure-compute',
    sectionTitle: 'Secure Compute',
    pageTitle: 'Vercel Secure Compute Overview',
  },
];

export function RecentArticles(): JSX.Element {
  const [recentArticles, setRecentArticles] = useState<Article[]>([]);

  useEffect(() => {
    const recentCookie = getCookie('docs-recents') as string;
    const recents: Article[] = recentCookie
      ? (JSON.parse(recentCookie) as Article[])
      : [];
    setRecentArticles(recents);
  }, []);

  function clearRecents(): void {
    setCookie('docs-recents', JSON.stringify([]));
    setRecentArticles([]);
  }

  return (
    <aside
      className={clsx(styles.sidebar, {
        [styles.sidebarVisible]: recentArticles,
      })}
    >
      <PopularArticles popularArticles={popularArticleData} />
      {recentArticles.length > 0 ? (
        <section className={styles.recentArticles}>
          <div className={styles.sidebarHeader}>
            <Text as="h3" className={styles.sidebarTitle} variant="heading-16">
              Recents
            </Text>
            <Button
              className={styles.sidebarButton}
              onClick={(): void => clearRecents()}
              type="tertiary"
            >
              Clear
            </Button>
          </div>
          <nav className={styles.sidebarArticles}>
            {recentArticles.map((article) => (
              <Link
                className={styles.sidebarArticle}
                href={article.path}
                key={article.path}
              >
                <div className={styles.sidebarArticleText}>
                  <span className={styles.sidebarArticleCategory}>
                    {article.sectionTitle}
                  </span>
                  <span className={styles.sidebarArticleTitle}>
                    {article.pageTitle}
                  </span>
                </div>
              </Link>
            ))}
          </nav>
        </section>
      ) : null}
    </aside>
  );
}

interface PopularArticlesProps {
  popularArticles: Article[];
}

function PopularArticles({
  popularArticles,
}: PopularArticlesProps): JSX.Element {
  return (
    <>
      <div className={styles.sidebarHeader}>
        <Text as="h3" variant="heading-16">
          Popular articles
        </Text>
      </div>

      <nav aria-label="Popular articles" className={styles.sidebarArticles}>
        {popularArticles.map((article: Article) => (
          <Link
            className={styles.sidebarArticle}
            href={article.path}
            key={article.path}
          >
            <div className={styles.sidebarArticleText}>
              <span className={styles.sidebarArticleCategory}>
                {article.sectionTitle}
              </span>
              <span className={styles.sidebarArticleTitle}>
                {article.pageTitle}
              </span>
            </div>
          </Link>
        ))}
      </nav>
    </>
  );
}
