import { memo, useState, type JSX } from 'react';
import { clsx } from 'clsx';
import Image from 'next/legacy/image';
import styles from './avatar.module.css';

interface QueryParams {
  hash?: string;
  size: number;
  teamId?: string | null;
  uid?: string;
  url?: string;
  username?: string | null;
}

// TODO: Export this function for unit testing
function buildUrl({
  hash,
  size,
  teamId,
  uid,
  url,
  username,
}: QueryParams): string {
  if (url) return url;

  const avatarUrl = new URL('https://vercel.com/api/www/avatar');
  const hasValidSHA = hash && /^[0-9a-f]{40}$/.test(hash);
  if (hasValidSHA) {
    avatarUrl.pathname = avatarUrl.pathname + hash;
    return avatarUrl.toString();
  }

  if (username) {
    avatarUrl.searchParams.append('u', username);
  } else if (teamId) {
    avatarUrl.searchParams.append('teamId', teamId);
  } else if (uid) {
    avatarUrl.pathname = avatarUrl.pathname + uid;
  }

  avatarUrl.searchParams.append('s', String(size * 2));
  return avatarUrl.toString();
}

export const GenericAvatar = memo(function GenericAvatar({
  title,
  src,
  size,
  className,
}: {
  title?: string;
  src?: string;
  size: number;
  className?: string;
}) {
  const [ready, setReady] = useState(false);

  return (
    <span
      className={clsx(styles.geistAvatar, className)}
      key={src}
      style={{
        width: size,
        height: size,
      }}
    >
      {!src ? null : (
        <Image
          alt={title}
          className={ready ? styles.ready : styles.loading}
          height={size}
          key={src}
          onLoadingComplete={(): void => setReady(true)}
          src={src}
          title={title}
          width={size}
        />
      )}
    </span>
  );
});

interface AvatarProps {
  title?: string;
  size?: number;
  height?: number | string;
  boxSize?: number;
  teamId?: string;
  username?: string;
  placeholder?: boolean;
  seed?: string;
  uid?: string;
  hash?: string;
  url?: string;
  className?: string;
}

export const Avatar: React.FC<AvatarProps> = memo(function Avatar({
  title,
  size = 80,
  height,
  boxSize = null,
  teamId = null,
  username = null,
  placeholder,
  seed,
  uid,
  hash,
  url,
  className,
}): JSX.Element {
  const avatarSize = parseInt(String(height || boxSize || size));

  if (placeholder) {
    if (seed) {
      return (
        <GenericAvatar
          className={className}
          size={avatarSize}
          src={`https://vercel.com/api/www/avatar?seed=${seed}`}
          title={title}
        />
      );
    }
    return (
      <GenericAvatar className={className} size={avatarSize} title={title} />
    );
  }

  const _url = buildUrl({ hash, size, teamId, uid, url, username });

  return (
    <GenericAvatar
      className={className}
      size={avatarSize}
      src={_url}
      title={title}
    />
  );
});
