/* TODO: This file uses styled jsx. If it's converted to CSS modules it may not need this directive. */
'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { Image as DatoCMSImage } from 'react-datocms';
import styles from './avatar.module.css';

export function DatoCMSAvatar({ title, slug, data, size, className }) {
  return (
    <span
      className={clsx(styles.geistAvatar, className)}
      key={slug}
      style={{
        width: size,
        height: size,
      }}
      title={title}
    >
      <DatoCMSImage data={data} />
    </span>
  );
}

DatoCMSAvatar.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  data: PropTypes.object,
  size: PropTypes.string,
  className: PropTypes.string,
};

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default DatoCMSAvatar;
