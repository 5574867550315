'use client';
import { clsx } from 'clsx';
import { VercelTriangle } from 'geist/icons';
import { ButtonLink } from '@pyra/multi-zone/link';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import type { MappedBlogPost } from '#/lib/contentful/blog';
import type { BlogPost } from './blog-post-card';
import { BlogPostCard } from './blog-post-card';
import styles from './home-page.module.css';

export function BlogPostSection({
  posts,
}: {
  posts: MappedBlogPost[];
}): JSX.Element {
  function transformMappedBlogPost(mappedBlogPost: MappedBlogPost): BlogPost {
    return {
      id: mappedBlogPost.id,
      title: mappedBlogPost.title,
      description: mappedBlogPost.description || '',
      authors:
        mappedBlogPost.authors?.map((author) => ({
          name: author.real,
          username: author.vercel,
        })) || [],
      publishedAt: mappedBlogPost.date,
      href: `/blog/${mappedBlogPost.slug}`,
      Icon: VercelTriangle,
    };
  }

  const blogPosts: BlogPost[] = posts.map(transformMappedBlogPost);

  return (
    <div className={styles.blogPostsSection}>
      <div className={styles.subheadingWrapper}>
        <div>
          <p
            className={clsx(
              styles.subheadingByline,
              styles.subheadingBylineLeft,
            )}
          >
            What&apos;s new
          </p>
          <h2 className={clsx(styles.subheading, styles.subheadingLeft)}>
            Stay in sync with Vercel updates
          </h2>
        </div>
        <div className={clsx(styles.blogButtonWrapper, styles.desktopOnly)}>
          <ButtonLink
            href="/blog"
            onClick={(): void => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs-cta',
                click_value: 'view_the_blog',
              });
            }}
            type="secondary"
            width="100%"
          >
            View The Blog
          </ButtonLink>
        </div>
      </div>
      <div className={styles.blogPosts}>
        {blogPosts.map((post, i) => (
          <BlogPostCard
            featured={i === 0}
            key={post.id}
            large={i < 2}
            post={post}
          />
        ))}
      </div>
      <div className={clsx(styles.blogButtonWrapper, styles.mobileOnly)}>
        <ButtonLink
          href="/blog"
          onClick={(): void => {
            analytics.track(AnalyticsEvent.CLICK_EVENT, {
              click_name: 'docs-cta',
              click_value: 'view_the_blog',
            });
          }}
          type="secondary"
          width="100%"
        >
          View The Blog
        </ButtonLink>
      </div>
    </div>
  );
}
