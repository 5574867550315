import { Stack } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import type { JSX } from 'react';
import type { BookIconType } from '#/app/components/icons/book-icon';
import { BookIcon } from '#/app/components/icons/book-icon';
import styles from './guides.module.css';

export function Guides({ guides }: { guides: BookProps[] }): JSX.Element {
  return (
    <section className={styles.guides}>
      <div className={styles.guidesScrollWrapper}>
        {guides.map((guide) => (
          <Book key={guide.title} {...guide} />
        ))}
      </div>
    </section>
  );
}

interface BookProps {
  title: string;
  subtitle: string;
  icon: BookIconType;
  href: string;
}

function Book({ title, subtitle, icon, href }: BookProps): JSX.Element {
  return (
    <Link className={styles.bookPerspective} href={href}>
      <div className={styles.bookRotateWrapper}>
        <Stack className={styles.book} direction="row">
          <div className={styles.bind} />
          <Stack
            className={styles.cover}
            direction="column"
            flex={1}
            justify="space-between"
          >
            <div>
              <h3>{title}</h3>
              <p>{subtitle}</p>
            </div>
            <BookIcon type={icon} />
          </Stack>
        </Stack>
      </div>
    </Link>
  );
}
