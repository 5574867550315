'use client';

import { clsx } from 'clsx';
import { Fragment, type JSX } from 'react';
import { Image } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import { ButtonLink } from '@pyra/multi-zone/link';
import { usePathname } from '@pyra/vercel-segment/navigation';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import styles from '#/app/components/home-page/home-page.module.css';
import type { TemplateAlgolia } from '#/lib/contentful/marketplace/templates';

export function NoTemplateOnlyTemplatesSection({
  templates,
  title,
  filter,
  frameworkFilter,
  limit,
}: {
  title: string;
  templates: TemplateAlgolia[];
  filter: string[] | undefined;
  frameworkFilter: string | undefined;
  limit: number;
}): JSX.Element {
  const pathname = usePathname();
  const isDocsHomepage = pathname === '/docs';

  const buildTemplateURL = (templateSlug: string, fw?: string): string => {
    return `/templates/${(fw || 'next.js').toLowerCase()}/${templateSlug}`;
  };

  const buildViewAllURLWithFilters = (filters: string[] = []): string => {
    const baseUrl = `/templates`;

    if (filters.length === 0) {
      return baseUrl;
    }

    const params = filters.map(
      (f) => `${encodeURIComponent(f.replace(/\s/g, '-')).toLowerCase()}`,
    );

    return `${baseUrl}/${params[0]}`;
  };

  return (
    <div className={styles.templateSection}>
      <div className={styles.subheadingWrapper}>
        <div>
          <p className={styles.subheadingByline}>Get started in minutes</p>
          <h2 className={styles.subheading}>{title}</h2>
        </div>

        <div className={clsx(styles.templateButtonWrapper, styles.desktopOnly)}>
          <ButtonLink
            href={buildViewAllURLWithFilters(filter)}
            onClick={(): void => {
              if (!isDocsHomepage) return;

              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs-cta',
                click_value: 'view_all_templates',
              });
            }}
            type="secondary"
            width="100%"
          >
            View All Templates
          </ButtonLink>
        </div>
      </div>
      <div className={styles.templates}>
        {templates.length !== 0 &&
          templates.slice(0, limit).map((template) => (
            <Fragment key={buildTemplateURL(template.slug, frameworkFilter)}>
              <Link
                className={styles.template}
                href={buildTemplateURL(template.slug, frameworkFilter)}
                onClick={(): void => {
                  if (!isDocsHomepage) return;

                  analytics.track(AnalyticsEvent.CLICK_EVENT, {
                    click_name: 'docs-cta',
                    click_value: template.name,
                  });
                }}
              >
                <div className={styles.templateImageWrapper}>
                  <Image
                    alt={template.name}
                    className={styles.templateImage}
                    height={337.5}
                    placeholder="blur"
                    srcDark={template.thumbnail}
                    srcLight={template.thumbnail}
                    width={600}
                  />
                  <div className={styles.templateImageScrim} />
                </div>
                <div className={styles.templateText}>
                  <p className={styles.templateTitle}>{template.name}</p>
                  <p className={styles.templateDescription}>
                    {template.description}
                  </p>
                </div>
              </Link>
            </Fragment>
          ))}
      </div>
      <div className={clsx(styles.templateButtonWrapper, styles.mobileOnly)}>
        <ButtonLink
          href={buildViewAllURLWithFilters(filter)}
          onClick={(): void => {
            if (!isDocsHomepage) return;

            analytics.track(AnalyticsEvent.CLICK_EVENT, {
              click_name: 'docs-cta',
              click_value: 'view_all_templates',
            });
          }}
          type="secondary"
          width="100%"
        >
          View All Templates
        </ButtonLink>
      </div>
    </div>
  );
}
