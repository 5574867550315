'use client';
import { ArrowRight } from 'geist/icons';
import { ButtonLink } from '@pyra/multi-zone/link';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import type { JSX } from 'react';
import styles from './home-page.module.css';

export function Hero(): JSX.Element {
  return (
    <div className={styles.heroSection}>
      <div className={styles.heroSectionInner}>
        <h1 className={styles.title}>Vercel Documentation</h1>
        <p className={styles.byline}>
          Vercel&apos;s Frontend Cloud provides the developer experience and
          infrastructure to <b>build</b>, <b>scale</b>, and <b>secure</b> a
          faster, more personalized web.
        </p>
        <div className={styles.heroButtons}>
          <div className={styles.mobileOnly}>
            <ButtonLink
              className={styles.mobileOnly}
              href="/docs/getting-started-with-vercel"
              onClick={(): void => {
                analytics.track(AnalyticsEvent.CLICK_EVENT, {
                  click_name: 'docs-cta',
                  click_value: 'start_with_a_tutorial',
                });
              }}
            >
              Start with a tutorial
            </ButtonLink>
          </div>
          <div className={styles.desktopOnly}>
            <ButtonLink
              href="/docs/getting-started-with-vercel"
              onClick={(): void => {
                analytics.track(AnalyticsEvent.CLICK_EVENT, {
                  click_name: 'docs-cta',
                  click_value: 'start_with_a_tutorial',
                });
              }}
              size="large"
              suffix={<ArrowRight />}
            >
              Start with a tutorial
            </ButtonLink>
          </div>
          <span className={styles.heroButtonsText}>or</span>
          <ButtonLink
            className={styles.heroButtonSecondary}
            href="/new"
            onClick={(): void => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs-cta',
                click_value: 'deploy_your_first_project',
              });
            }}
            size="large"
            type="secondary"
          >
            Deploy your first project
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}
