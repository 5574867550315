'use client';
import type { VercelTriangle } from 'geist/icons';
import type { ComponentProps, JSX } from 'react';
import { clsx } from 'clsx';
import { format, parseISO } from 'date-fns';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import { Link } from '@pyra/multi-zone/link';
import AvatarGroup from '#/app/components/avatar/group';
import styles from './home-page.module.css';

export interface BlogPost {
  id: string;
  title: string;
  description: string;
  authors: { name: string; username: string }[];
  publishedAt: string;
  href: string;
  Icon: typeof VercelTriangle;
}

export function BlogPostCard({
  post,
  featured,
  large,
}: {
  post: BlogPost;
  featured: boolean;
  large: boolean;
}): JSX.Element {
  return (
    <Link
      className={clsx(styles.blogPost, {
        [styles.blogPostFeatured]: featured,
        [styles.blogPostLarge]: large,
      })}
      href={post.href}
      key={post.title}
      onClick={(): void => {
        analytics.track(AnalyticsEvent.CLICK_EVENT, {
          click_name: 'docs-cta',
          click_value: post.title,
        });
      }}
    >
      {Boolean(featured) && (
        <div className={styles.blogPostFeaturedIcon}>
          <FeaturedPostIcon />
        </div>
      )}
      <div className={styles.blogPostIconWrapper}>
        <post.Icon size={16} />
      </div>
      <div className={styles.blogPostText}>
        {Boolean(featured) && (
          <p className={styles.blogPostDescription}>{post.description}</p>
        )}
        <p className={styles.blogPostTitle}>{post.title}</p>
      </div>
      {!featured && (post.authors.length > 0 || post.publishedAt) ? (
        <div className={styles.blogPostAuthorsAndDate}>
          {post.authors.length > 0 && (
            <div className={styles.blogPostAuthors}>
              <div>
                <AvatarGroup members={post.authors.slice(0, 5)} size={25} />
              </div>
            </div>
          )}
          {post.publishedAt ? (
            <p className={styles.blogPostDate}>
              {format(parseISO(post.publishedAt), 'MMM. do yyyy')}
            </p>
          ) : null}
        </div>
      ) : null}
    </Link>
  );
}

function FeaturedPostIcon(props: ComponentProps<'svg'>): JSX.Element {
  return (
    <svg
      fill="none"
      height={353}
      viewBox="0 0 411 353"
      width={411}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#fff" strokeWidth={1.5}>
        <path d="M205.603 2.003l203.603 349.995H2L205.603 2.003z" />
        <path
          d="M155.124 264.498L205.604 177h-100.96l50.48 87.498z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.083 264.498L306.563 177h-100.96l50.48 87.498z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M205.603 176.999l50.48 87.499h-100.96l50.48-87.499zM205.603 2l50.48 87.499h-100.96L205.603 2zM357.043 264.498h-100.96l50.48-87.499v0M104.642 176.999l50.48 87.499H54.162"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.124 264.498l50.48 87.499h-100.96l50.48-87.499zM155.124 89.5l50.48 87.5h-100.96l50.48-87.5z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.083 264.498l50.48 87.499h-100.96l50.48-87.499zM256.083 89.5l50.48 87.5h-100.96l50.48-87.5z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M306.563 352l50.48-87.499h-100.96L306.563 352zM104.642 352l50.48-87.499H54.162L104.642 352z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
